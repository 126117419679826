/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 5.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

// Prefix for :root CSS variables

@layer bootstrap-reboot, primevue;
$variable-prefix: "ct-";
//Fonts
@import "../../custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "../../config/default/variables";
@import "../../config/default/light-mode";
@import "../../config/default/dark-mode";
@import "../../config/default/custom-variables";
@import "./node_modules/bootstrap/scss/bootstrap";

//Components
@import "../../custom/components/accordions";
@import "../../custom/components/alert";
@import "../../custom/components/badge";
@import "../../custom/components/breadcrumb";
@import "../../custom/components/buttons";
@import "../../custom/components/card";
@import "../../custom/components/dropdown";
@import "../../custom/components/forms";
@import "../../custom/components/modal";
@import "../../custom/components/nav";
@import "../../custom/components/pagination";
@import "../../custom/components/popover";
@import "../../custom/components/progress";
@import "../../custom/components/reboot";
@import "../../custom/components/tables";
@import "../../custom/components/type";
@import "../../custom/components/utilities";


// Structure
@import "../../custom/structure/general";
@import "../../custom/structure/left-menu";
@import "../../custom/structure/topbar";
@import "../../custom/structure/page-head";
@import "../../custom/structure/footer";
@import "../../custom/structure/right-sidebar";
@import "../../custom/structure/layouts";
@import "../../custom/structure/horizontal-nav";
@import "../../custom/structure/two-column-menu";


//Components
@import "../../custom/components/avatar";
@import "../../custom/components/helper";
@import "../../custom/components/social";
@import "../../custom/components/widgets";
@import "../../custom/components/custom-checkbox";
@import "../../custom/components/ribbons";
@import "../../custom/components/print";
@import "../../custom/components/preloader";

//Pages
@import "../../custom/pages/authentication";
@import "../../custom/pages/components-demo";
@import "../../custom/pages/error";
@import "../../custom/pages/logout";
@import "../../custom/pages/faq";
@import "../../custom/pages/maintenance";
@import "../../custom/pages/timeline";
@import "../../custom/pages/email";
@import "../../custom/pages/sitemap";
@import "../../custom/pages/search-results";
@import "../../custom/pages/pricing";
@import "../../custom/pages/gallery";
@import "../../custom/pages/coming-soon";
@import "../../custom/pages/profile";
@import "../../custom/pages/task";
@import "../../custom/pages/kanbanboard";
@import "../../custom/pages/ecommerce";
@import "../../custom/pages/order-detail";


// Plugins
@import "../../custom/plugins/waves";
@import "../../custom/plugins/apexcharts";
@import "../../custom/plugins/ion-rangeslider";
@import "../../custom/plugins/calendar";
@import "../../custom/plugins/colorpicker";
@import "../../custom/plugins/sparkline-chart";
@import "../../custom/plugins/datatable";
@import "../../custom/plugins/daterange";
@import "../../custom/plugins/form-wizard";
@import "../../custom/plugins/select2";
@import "../../custom/plugins/slimscroll";
@import "../../custom/plugins/toaster";
@import "../../custom/plugins/sweetalert";
@import "../../custom/plugins/selectize";
@import "../../custom/plugins/tippy";
@import "../../custom/plugins/nestable-list";
@import "../../custom/plugins/hopscotch";
@import "../../custom/plugins/flot";
@import "../../custom/plugins/morris";
@import "../../custom/plugins/chartjs";
@import "../../custom/plugins/chartist";
@import "../../custom/plugins/c3";
@import "../../custom/plugins/ricksaw";
@import "../../custom/plugins/responsive-table";
@import "../../custom/plugins/footables";
@import "../../custom/plugins/bootstrap-tables";
@import "../../custom/plugins/tablesaw";
@import "../../custom/plugins/jsgrid";
@import "../../custom/plugins/multiple-select";
@import "../../custom/plugins/autocomplete";
@import "../../custom/plugins/bootstrap-touchspin";
@import "../../custom/plugins/parsley";
@import "../../custom/plugins/flatpickr";
@import "../../custom/plugins/clockpicker";
@import "../../custom/plugins/quilljs-editor";
@import "../../custom/plugins/dropzone";
@import "../../custom/plugins/dropify";
@import "../../custom/plugins/x-editable";
@import "../../custom/plugins/cropper";
@import "../../custom/plugins/google-maps";
@import "../../custom/plugins/vector-maps";
@import "../../custom/plugins/mapeal-maps";
@import "../../custom/plugins/datepicker";
@import "../../custom/plugins/dragula";